.banner{
  height: 80vh;
  width: 100vw;
  margin-top: -15vh;
  margin-left: -10vw;
  position: relative;
  background-color: black;
}

.layer{
  position: absolute;
  width: inherit;
  height: inherit;
  z-index: 50;
  opacity: 1;
}

.image{
  height: 80vh;
  width: inherit;
  object-fit: cover;
  opacity: 0.7;
}

.text{
  z-index: 60 !important;
  text-shadow: 0 0 10px black;
}