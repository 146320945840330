.nav-options {
  display: flex;
  align-items: center;
  gap: 2vw;
}

.option {
  color: white;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: var(--bolder);
  transition: 0.1s ease-out;
  opacity: 0.8;
}

.active-option{
  opacity: 1;
  border-bottom: solid white; 
}

.option:hover{
  opacity: 1;
  transition: 0.1s ease-in;
  border-bottom: solid white;
}

.donate {
  background-color: var(--theme);
  height: 10vh;
  width: 10vw;
  display: flex;
  align-items: center;
  font-size: 1.4em;
  justify-content: center;
  color: black;
  text-transform: uppercase;
  font-weight: var(--bold);
  opacity: 1;
  z-index: 100;
  transition: 0.5s ease;
}

.donate:hover{
  opacity: 1;
  scale: 1.2;
  transition: 0.5s ease;
}

/* ------------- responsive css -------------- */
@media screen and (max-width: 1000px) {
  .nav-options{
    background-color: var(--accent);
    position: fixed;
    top: 10vh;
    left: 0;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    width: 100vw;
    box-sizing: border-box;
    gap: 2vh;
  }

  .donate{
    height: 8vh;
    width: 40vw;
  }

  .option{
    opacity: 1;
  }
}