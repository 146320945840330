.App{
  position: relative;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button:active{
  scale: 0.9;
}

a {
  color: rgb(73, 187, 240);
  font-weight: var(--normal);
  text-decoration: none;
}

input {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

.carousel-control-prev-icon, .carousel-control-next-icon{
  z-index: 60;
  scale: 1.5;
  filter: brightness(100%);
}



@media screen and (max-width:1000px) {
  .App{
    overflow-x: hidden;
  }
}

