.form-input:focus-within .label {
  color: var(--accent);
}

.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 1.2em;
}

.input {
  font-size: 1.3em;
  padding: 2vh 1vw;
  border-radius: 2px;
  border: 1px solid rgb(171, 171, 171);
  outline: none;
}

.input:focus {
  border: 1px solid var(--accent);
}
