.contact {
  width: 100%;
  padding: 5vh 10vw;
  display: flex;
  justify-content: space-between;
}

/* ----------- responsive css ------------ */
@media screen and (max-width: 1000px) {
  .contact {
    flex-direction: column;
    gap: 5vh;
  }

  .contact-num,
  .mail {
    width: 100%;
  }

  .contact-num {
    padding-bottom: 5vh;
    border-bottom: 1px solid;
  }
}

/*##################################################
                    CONTACTS
  ##################################################*/

.contact-num {
  width: 50vw;
}

.text {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.nums {
  width: 20vw;
  padding-top: 2vh;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-bottom: 4vh;
}

.phone {
  border-left: 1px solid;
  padding-left: 2vh;
  transition: 0.5s ease;
}

.phone:hover {
  scale: 1.1;
  transition: 0.5s ease;
  margin-left: 1.5vw;
}

.name {
  font-weight: var(--bolder);
  font-size: 1.3em;
  color: var(--accent);
}

.email {
  font-size: 1.3em;
  color: var(--accent);
  transition: 0.5 ease;
}

.email:hover {
  text-decoration: underline;
  opacity: 1;
  transition: 0.5s ease;
}

/*##################################################
                    FORM
  ##################################################*/
.mail {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5vh;
}

.heading {
  font-size: 1.4em;
}

.form {
  display: flex;
  width: 50vw;
  flex-direction: column;
  gap: 1vh;
  position: relative;
  padding-bottom: 10vh;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  gap: 1vw;
}

.submit {
  position: absolute;
  right: 0;
  bottom: 0;
  font-weight: var(--bolder);
  font-size: 1.3em;
  border-radius: 2px;
  padding: 1vh 2vw;
  width: fit-content;
  background-color: var(--accent);
  color: white;
}

/* --------- responsive css ---------- */
@media screen and (max-width: 1000px) {
  .contact-info {
    flex-direction: column;
  }

  .submit {
    padding: 1vh 3vh;
  }
}

/*##################################################
                    INPUT
  ##################################################*/
.form-text:focus-within .message-label {
  color: var(--accent);
}

.form-text{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message-label {
  font-size: 1.2em;
}

.message {
  font-size: 1.3em;
  padding: 2vh 1vw;
  border-radius: 2px;
  border: 1px solid rgb(171, 171, 171);
  outline: none;
}

.message {
  resize: none;
  height: 30vh;
}

.message:focus {
  border: 1px solid var(--accent);
}
