body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y:scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* css variables for whole website */
:root {
  /* theme colors */
  --theme:#f7da02;
  --grey: rgb(171, 171, 171);
  --accent: #89664d;
  --border-style: 1px solid grey;

  /* font values */
  --thin: 100;
  --thinner: 200;
  --normal: 400;
  --bolder: 600;
  --bold: 800;
}