.home {
    /* padding: 5vh 10vw; */
    background-color: #f8f9fa;
  }
  .cover-pic {
    width: 100%;
    display: flex;
    object-fit: cover;
    overflow: hidden;
    justify-content: center;
    margin-bottom: 4vh;
    max-height: 83vh;
  }

  .title{
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .cover {
    max-width: 100%;
    width: 100%;
    height: 130vh;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin-bottom: 15px;
    display: flex;
    gap: 2vh;
    padding: 4vh 10vh;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
  }
  
  .section:hover {
    transform: scale(1.02);
  }
  
  .reverse {
    flex-direction: row-reverse;
  }
  
  .image {
    width: 30%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .text {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 0 2vw;
  }
  
  .right {
    align-items: end;
  }
  
  .heading {
    font-family: 'Times New Roman', Times, serif;
    color: #333333;
    font-weight: bold;
    font-size: 2.5em;
    margin-bottom: 1vh;
    position: relative;
  }
  
  .heading::after {
    content: "";
    width: 50px;
    height: 5px;
    background-color: var(--accent);
    position: absolute;
    bottom: -10px;
    left: 0;
  }
  
  .content {
    width: 100%;
    font-size: 1.2em;
    text-align: justify;
    color: #555555;
    line-height: 1.6;
  }
  
  .content ul {
    list-style: disc;
    padding-left: 20px;
  }
  
  .content ul li {
    margin: 1vh 0;
  }
  
  @media screen and (max-width: 1000px) {
    .section {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .text {
      width: 100%;
      text-align: center;
      padding: 0;
    }
  
    .image {
      width: 80%;
    }
  
    .heading {
      font-size: 2.2em;
    }
  
    .content {
      font-size: 1em;
    }
  }
  