.about {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 5vh; 
  gap: 5vh;
  background-color: #f9f9f9;
  color: #333;
}

.cover {
  width: 100vw; 
  height: auto;
  max-height: 130vh;
  object-fit: cover;
  margin-bottom: 3vh; 
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
/*##################################################
                    SECTION
##################################################*/

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.heading {
  text-align: center;
  font-family: 'Merriweather', serif;
  color: #2c3e50;
  font-weight: 700;
  font-size: 2.5em;
  margin-bottom: 0.5em;
  border-bottom: 2px solid #ec7063;
  padding-bottom: 0.5em;
}

.content {
  width: 100%;
  font-size: 1.1em;
  line-height: 1.6;
  text-align: justify;
}

/*##################################################
                    TRUSTEES
##################################################*/

.trustees {
  margin-top: 2vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 2em;
}

.quote {
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: italic;
  font-size: 1.7em;
  font-weight: bold;
  color: #e74c3c;
  background: #fdf2f2;
  padding: 1em;
  border-radius: 10px;
  margin: 2em 0;
}

.trustee {
  width: 20%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  background: #ffffff;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.trustee:hover {
  transform: translateY(-10px);
}

.name {
  font-size: 1.4em;
  font-weight: 700;
  color: #34495e;
}

.post {
  font-size: 1.2em;
  font-style: italic;
  color: #7f8c8d;
}

.image-holder {
  width: 100%;
  aspect-ratio: 1;
  background-color: #bdc3c7;
  border-radius: 10px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.9;
  transition: opacity 0.5s ease;
}

.image:hover {
  opacity: 1;
}

/* ---------- responsive css ------------ */
@media screen and (max-width: 1000px) {
  .heading {
    font-size: 2em;
    line-height: 1.2;
  }

  .content {
    font-size: 1em;
  }

  .trustees {
    gap: 2em;
    flex-wrap: wrap;
  }

  .trustee {
    width: 45%;
    gap: 0.5em;
  }

  .image {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .heading {
    font-size: 1.8em;
    line-height: 1.2;
  }

  .content {
    font-size: 0.9em;
  }

  .trustees {
    gap: 1.5em;
  }

  .trustee {
    width: 100%;
    gap: 0.5em;
  }

  .image {
    opacity: 1;
  }
}
