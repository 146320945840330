.bg-yellow-custom {
    background: linear-gradient(135deg, #f8d800, rgb(124, 124, 3));
    color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1;
    min-width: 150px;
    margin-bottom: 2rem;
    text-align: center;
}

.footer-section h6 {
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
    color: #fff;
    position: relative;
    font-weight: 700;
    transition: color 0.3s ease;
}

.footer-section h6::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    transition: width 0.4s ease;
}

.footer-section h6:hover::after {
    width: 100%;
}

.footer-section h6:hover {
    color: #ff6a00;
}

.social-icons {
    margin-top: 20px;
    text-align: center;
}

.social-icons a {
    color: #fff;
    margin: 0 10px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
    color: #ff6a00;
    transform: scale(1.2);
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9rem;
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 10px;
}
