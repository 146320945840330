.container {
  text-align: center;
  padding: 20px;
}

.paymentDetails {
  margin-bottom: 30px;
}

.paymentBox {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.paymentBox p {
  margin: 0px ;
  font-size: 16px;
}
.text{
  text-align: center;
}
.paymentBox span {
  font-weight: bold;
}

.qrImage {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  padding-bottom: 3vh;
}

.button {
  flex: 1;
  max-width: 150px;
}

.buttons{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2vh 5vw;
  bottom: 0;
}
.pageButton{
  font-size: 1.3em;
  margin-top: 30px;
  bottom: 2vh;
  right: 5vw;
  border: 3px solid var(--accent);
  color: var(--accent);
  border-radius: 2px;
  padding: 1vh 2vw;
  transition: 0.2s ease;
  margin-right  : 20px;
}
.pageButton:hover{
  transition: 0.2s ease;
  color: white;
  background-color: var(--accent);
}
.buttons > .page-button{
  position: relative;
}

.formText {
  /* margin: 50px 0; */
  font-size: 2.5rem;
  color: #333;
  /* margin-top: 50px; */
}
.formText1 {
  /* margin: 50px 0; */
  font-size: 4.5em;
  color: #333;
  margin-top: 0px;
}

@media screen and (max-width: 600px) {
  .paymentBox {
    padding: 10px;
  }

  .paymentBox p {
    font-size: 14px;
  }

  .button {
    max-width: 100px;
  }

  .formText1 {
    /* margin: 50px 0; */
    font-size: 3rem;
  }

  .formText {
    /* margin: 50px 0; */
    font-size: 2rem;
  }
}
