.donate {
  padding: 5vh 10vw;
  padding-top: 3vh;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.form {
  border: var(--border-style);
  border-radius: 2px;
  padding: 15vh 5vw;
  position: relative;
}

.heading {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--accent);
  width: 100%;
  text-align: center;
  padding: 2vh 0;
  font-weight: var(--bolder);
  color: white;
  font-size: 2em;
}

/*##################################################
                  PAGE BUTTONS
  ##################################################*/

.buttons{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2vh 5vw;
  bottom: 0;
}

.buttons > .page-button{
  position: relative;
}

.page-button{
  font-size: 1.3em;
  position: absolute;
  bottom: 2vh;
  right: 5vw;
  border: 3px solid var(--accent);
  color: var(--accent);
  border-radius: 2px;
  padding: 1vh 2vw;
  transition: 0.2s ease;
}
.page-button1{
  font-size: 1.3em;
  position: absolute;
  bottom: 2vh;
  right: 5vw;
  border: 3px solid var(--accent);
  color: var(--accent);
  border-radius: 2px;
  padding: 1vh 2vw;
  transition: 0.2s ease;
  margin-right  : 200px;
}

.page-button:hover{
  transition: 0.2s ease;
  color: white;
  background-color: var(--accent);
}
.page-button1:hover{
  transition: 0.2s ease;
  color: white;
  background-color: var(--accent);
}
/*##################################################
                    BANNER
  ##################################################*/
.banner {
  position: relative;
  margin-top: -10vh;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 60vh;
  width: 100vw;
  margin-left: -10vw;
}

.banner-image {
  filter: brightness(60%);
  position: absolute;
  z-index: 0;
  object-fit: cover;
  height: inherit;
  width: 100%;
}

.banner-title {
  margin-bottom: 5vh;
  font-size: 5em;
  color: rgb(221, 221, 221);
  font-weight: bold;
  text-shadow: 0px 0px 2px black;
  z-index: 10;
}

/*##################################################
                    AMOUNT
  ##################################################*/
.amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  align-items: center;
}

.values {
  display: flex;
  flex-wrap: wrap;
  gap: 5vh;
}

.value {
  border-radius: 2px;
  color: white;
  background-color: var(--accent);
  font-size: 1.5em;
  padding: 0.5vh 1vw;
}

.other{
  display: flex;
  align-items: center;
  gap: 2vh;
}

.other-label{
  font-size: 1.5em;
}

.other-value {
  font-size: 1.6em;
  padding: 1vh 1vw;
  border-radius: 2px;
  border: var(--border-style);
  outline: none;
}

/* -------- reponsive css -------- */
@media screen and (max-width: 1000px){
  .values{
    gap: 0;
    justify-content: center;
  }

  .value{
    scale: 0.8;
  }

  .other-value{
    width: 80%;
  }

}

/*##################################################
                    DETAILS
  ##################################################*/
.details {
  border-radius: 2px;
  width: 100%;
  display: flex;
  gap: 2vw;
}

.person,
.address {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
}

.states {
  display: flex;
  flex-direction: column;
}

.states:focus-within .label {
  color: var(--accent);
}

.label {
  font-size: 1.2em;
}

.select {
  font-size: 1.3em;
  padding: 2.2vh 1vw;
  border-radius: 2px;
  border: 1px solid rgb(171, 171, 171);
  outline: none;
  background-color: transparent;
}
.certificateInfo {
  font-weight: bolder;
  margin-top: 20px;
  font-size: 25px;
  color: var(--accent);
  text-align: center;
}
/* --------- response css ----------- */
@media screen and (max-width: 1000px){
  .details{
    flex-direction: column;
  }

  .person{
    padding-bottom: 5vh;
    border-bottom: var(--border-style);
  }
}
