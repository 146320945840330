
.work {
    font-family: 'Arial', sans-serif;
  }

/* Hero Section */
.hero {
    position: relative;
    overflow: hidden;
    background-image: url('assets/images/Work/MBP trust/Work2.jpg'); 
    background-size: cover;
    background-position: center;
    color: #fff; 
    text-align: center;
    padding: 200px 20px;
  }
  
  .heroContent {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .heroTitle {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .heroDescription {
    font-size: 1.5rem;
    line-height: 1.6;
  }
  .hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); 
    z-index: -1;
  }
  
  /* Image Container */
  .image-container {
    position: relative;
  }
  
  .cover {
    max-width: 100%;
  }
  
  .image-text {
    text-align: center;
    position: absolute;
    bottom: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
  }
  
/* Impact Section */
.impact {
    padding: 80px 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .sectionTitle {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
  }

  .mainTitle{
    font-size: 2.6rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
  }
  
  .sectionDescription {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 40px;
  }

  .maindesc{
    font-size: 1.3rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  
  .impactGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    width: 100%;
    max-width: 1200px;
    margin-top: 40px;
  }
  
  .impactCard {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .impactCard:hover {
    transform: translateY(-5px);
  }
  
  .cardTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #cebd67;
    margin-bottom: 10px;
  }
  
  .cardText {
    font-size: 1.1rem;
    color: #c2b32b;
    line-height: 1.6;
  }
  @media (max-width: 768px) {
    .impactGrid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Donation */
  .callToAction {
    background-color: #f4f4f4;
    padding: 40px 20px;
    text-align: center;
  }
  
  .callToActionContent {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .donateButton {
    background-color: var(--accent);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .donateButton:hover {
    opacity: 0.9;
  }
  
  
  .donateButton:hover {
    background-color: #715e0b; 
  }
  
  
  @media (max-width: 768px) {
    .hero {
      flex-direction: column;
      text-align: center;
    }
  
    .heroContent {
      margin-right: 0;
      margin-bottom: 40px;
    }
  
    .impactGrid, .trusteeGrid {
      grid-template-columns: 1fr;
    }
  
    .donateButton, .cardTitle, .testimonialQuote {
      font-size: 1.1rem;
    }
  }
  /* Stories of Change Section */
.stories {
    padding: 80px 0;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .storiesContent {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .storyCards {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }
  
  .storyCard {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .storyCard:hover {
    transform: translateY(-10px);
  }
  
  .cardImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .cardContent {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px;
    color: #fff;
    transition: opacity 0.3s ease;
    opacity: 0;
  }
  
  .storyCard:hover .cardContent {
    opacity: 1;
  }
  
  .cardTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    text-decoration-color: #fff;
  }
  
  .cardText {
    font-size: 1rem;
    line-height: 1.6;
  }

  .gallery {
    padding: 20px;
  }
  
  .galleryGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .galleryImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }

  .callToAction {
    background-color: #f4f4f4;
    padding: 40px 20px;
    text-align: center;
  }
  
  .callToActionContent {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .donateButton {
    font-weight: var(--bolder);
    font-size: 1.3em;
    border-radius: 2px;
    padding: 0.8vh 1vw;
    width: fit-content;
    background-color: var(--accent);
    color: white;
  }
  
  .donateButton:hover {
    background-color: #715e0b; 
  }
  
  /* Donation */
  .callToAction {
    background-color: #f4f4f4;
    padding: 40px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .callToActionContent {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
