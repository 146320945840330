.navbar {
  display: flex;
  height: 10vh;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: var(--accent);
  transition: 0.5s ease;
  padding-left: 5vw;
  opacity: 0.9;
  box-shadow: 0 2px 10px rgb(34, 34, 34);
}

.navbar:hover {
  transition: 0.5s ease;
  opacity: 1;
}

.mbp {
  z-index: 100;
  display: flex;
  align-items: center;
  transition: 0.5s ease;
  font-family: "Times New Roman", Times, serif;
}

.logo {
  height: 10vh;
  object-fit: contain;
}

.name {
  font-weight: bold;
  font-size: 2em;
  color: black;
}

.mbp:hover {
  scale: 1.2;
  transition: 0.5s ease;
}

.nav-button{
  height: 5vh;
}

/* ----------- responsive css ------------- */
@media screen and (max-width: 1000px){
  .navbar{
    padding: 0 2vw;
    opacity: 1;
  }
}
