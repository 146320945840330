.go-up{
  height: 7vh;
  width: 7vh;
  background-color: var(--accent);
  position: fixed;
  bottom: 5vh;
  right: 5vh;
  z-index: 10;
  padding: 1vw;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.image{
  height: 100%;
  filter: invert(100%);
}

/* ------- responsive css -------- */
@media screen and (max-width: 1000px){
  .go-up{
    height: 5vh;
    width: 5vh;
    right: 45vw;
    bottom: 1vh;
    padding: 2vw;
  }
}