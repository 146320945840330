.home{
  padding: 5vh 10vw;
}


/* *COPIED FROM ABOUT PAGE* */

/* ---- section ----- */

.section{
  display: flex;
  gap: 2vh;
  padding: 2vh 0;
}

.reverse{
  flex-direction: row-reverse;
}

.image{
  width: 30%;
  object-fit: cover;
}


/* ---- text ----- */

.text{
  display: flex;
  flex-direction: column;
  width: 70%;
}

.right{
  align-items: end;
}

.full{
  width: 100%;
  align-items: center;
}

/* ---- content ----- */

.heading{
  font-family: 'Times New Roman', Times, serif;
  color: var(--accent);
  font-weight: var(--bolder);
  font-size: 2.5em;
}

.imp{
  width: 400px;
  height: auto;
}

.content{
  width: 100%;
  font-size: 1.2em;
  text-align: justify;
}

.span{
  font-weight: bold;
}

@media screen and (max-width: 1000px){
  .section{
    flex-direction: column;
    align-items: center;
  }
  
  .text{
    width: 100%;
    text-align: center;
  }

  .heading{
    font-size: 2.2em;
  }

  .content{
    font-size: 1em;
  }
}

/* Add new styles for the registration section */
.registrationSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  flex-direction: column;
}

.imp {
  width: 100%;
  max-width: 400px; /* Limiting the maximum width */
  height: auto;
  margin-bottom: 10px; /* Adding some space below the image */
}

.registrationText {
  font-weight: 700;
  font-size: 1.2em; /* Increase font size for better readability */
  text-align: center; /* Center-align the text */
}

.registrationNumber {
  font-weight: bold;
  color: var(--accent); /* Using the accent color */
}
