.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
}

.h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.p {
  font-size: 1.2rem;
  color: #666;
}
